import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://e1ec22a360c740a4b2c046dd5800933e@o486370.ingest.sentry.io/6508971",
  environment: process.env.GATSBY_ENVIRONMENT ?? "local",
  sampleRate: 0.7,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.02,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: true,
    }),
  ],
});
